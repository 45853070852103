$(document).ready(function () {
  if (gon.canned_reports) {
    gon.canned_reports.forEach(function (element) {
      $('#pre-made-reports').append('<option id= ' + element.index + '>' + element.name + '</option>')
    })

    $('#pre-made-reports').change(function (data) {
      const that = this
      if (that.value === '') {
        clearAllData()
      } else {
        const report = gon.canned_reports.filter(function (x) { return x.name === that.value })[0]
        removeExtraDivs()
        populateEntries(report)
      }
    })
  }

  function removeExtraDivs () {
    $('.nested_attribute:not(:first)').remove()
  }

  function clearAllData () {
    $('.nested_attribute').remove()
    $('.add_attribute').click()
  }

  function populateEntries (report) {
    times(report.parameters.length - 1)(function () {
      $('.add_attribute')[0].click()
    })

    $('#report_group_operator').val(report.group_operator || 'and')

    report.parameters.forEach(function (param, index) {
      groups = param.groups
      multiple_groups = param.multiple_groups
      start_date_after = param.start_date_after
      end_date_after = param.end_date_after
      start_date_before = param.start_date_before
      end_date_before = param.end_date_before
      active_as_of = param.active_as_of
      include_current = param.include_current
      include_pre_reassignment_memberships = param.include_pre_reassignment_memberships
      include_initial_ccsg = param.include_initial_ccsg

      if (groups) {
        const ids = groups.map(function (group) {
          return group.id
        })
        $($('.group-select')[index]).val(ids).trigger('change')
      }

      $($('[id^=report_parameters_attributes_][id$=_multiple_groups]')[index]).prop('checked', !!(multiple_groups))
      $($('[id^=report_parameters_attributes_][id$=_active_as_of]')[index]).val(active_as_of)
      $($('[id^=report_parameters_attributes_][id$=_start_date_after]')[index]).val(start_date_after)
      $($('[id^=report_parameters_attributes_][id$=_start_date_before]')[index]).val(start_date_before)
      $($('[id^=report_parameters_attributes_][id$=_end_date_before]')[index]).val(end_date_before)
      $($('[id^=report_parameters_attributes_][id$=_end_date_after]')[index]).val(end_date_after)
      $($('[id^=report_parameters_attributes_][id$=_include_current]')[index]).prop('checked', !!(include_current))
      $($('[id^=report_parameters_attributes_][id$=_include_pre_reassignment_memberships]')[index]).prop('checked', !!(include_pre_reassignment_memberships))
      $($('[id^=report_parameters_attributes_][id$=_include_initial_ccsg]')[index]).prop('checked', !!(include_initial_ccsg))
    })
  }

  // Hey Gary,
  //   Why?
  // -Fang
  const times = function (x) {
    return function (f) {
      if (x > 0) {
        f()
        times(x - 1)(f)
      }
    }
  }
})
